import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import color, { themeColors } from "../styles/colors";
import { clamp, relativeProgress, interpolate } from "../libs/math";
import sliderBgImg from "../images/bar-bg.png";
import sliderBgImgLight from "../images/bar-bg-light.png";
import { spacingRestricted, fluidRestricted } from "../styles/mixins";

const SliderMain = styled.div`
	width: 100%;
	height: 10px;
	border-radius: 101px;
	position: relative;

	${fluidRestricted("height", 8, 10)}
`;

const SliderBarWrap = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 101px;
	overflow: hidden;
	position: absolute;
	background: repeating-linear-gradient(
		45deg,
		rgba(0,0,0,0),
		rgba(0,0,0,0) 27%,
		${(props) => themeColors[props.colorTheme].fg} 27%,
		${(props) => themeColors[props.colorTheme].fg} 50%,
		rgba(0,0,0,0) 50%
	) top left;
	background-size: 7px 7px;
`;

const SliderBar = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	right: 100%;
	background-color: ${color("orange")};
`;

const SliderSlide = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	pointer-events: none;
`;

const SliderDot = styled.div`
	pointer-events: auto;
	position: absolute;
	width: 30px;
	height: 30px;
	top: 50%;
	margin-top: -15px;
	margin-border-right-width: -15px;
	right: 0;
	border-radius: 15px;
	background-color: ${color("orange")};

	${fluidRestricted("width", 26, 30)}
	${fluidRestricted("height", 26, 30)}

	${fluidRestricted("margin-right", -13, -15)}
	${fluidRestricted("margin-top", -13, -15)}
`;
const SliderPadding = styled.div`
	${spacingRestricted("margin-bottom", "s")}
	${spacingRestricted("margin-top", "s")}
`;

const Slider = ({
	onUpdate = () => {},
	setSlide = 0,
	setSlidePercCallback,
	range = { min: 0, max: 1 },
	colorTheme = "dark",
}) => {
	const handle = useRef();
	const widthRef = useRef();
	const [slidePerc, setSlidePerc] = useState(0);
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		const val = Math.round(interpolate(slidePerc, range.min, range.max));

		onUpdate(val);
	}, [range]);

	useEffect(() => {
		const grab = handle.current;

		function move(x) {
			const { width, left } = widthRef.current.getBoundingClientRect();
			const perc = clamp((x - left) / width, 0, 1);

			const val = Math.round(interpolate(perc, range.min, range.max));

			onUpdate(val);
			setSlidePerc(perc);
			setSlidePercCallback && setSlidePercCallback(perc);
		}

		function md(e) {
			e.preventDefault();
			setIsActive(true);
		}

		function mm(e) {
			e.preventDefault();
			const { clientX: x } = e;
			if (!isActive) return;
			move(x);
		}
		function mu(e) {
			setIsActive(false);
		}

		function tm(e) {
			// e.preventDefault() // causing issue with scroll on older iPad
			const { clientX: x } = e.changedTouches[0];
			if (!isActive) return;
			move(x);
		}

		grab.addEventListener("mousedown", md);
		window.addEventListener("mousemove", mm);
		window.addEventListener("mouseup", mu);
		grab.addEventListener("touchstart", md);
		window.addEventListener("touchmove", tm);
		window.addEventListener("touchend", mu);

		return () => {
			grab.removeEventListener("mousedown", md);
			window.removeEventListener("mousemove", mm);
			window.removeEventListener("mouseup", mu);
			grab.removeEventListener("touchstart", md);
			window.removeEventListener("touchmove", tm);
			window.removeEventListener("touchend", mu);
		};
	}, [range, isActive]);

	useEffect(() => {
		const perc = relativeProgress(setSlide, range.min, range.max);
		setSlidePerc(perc);
		setSlidePercCallback && setSlidePercCallback(perc);
	}, [setSlide]);

	return (
		<SliderPadding>
			<SliderMain>
				<SliderBarWrap ref={widthRef} colorTheme={colorTheme}>
					<SliderBar
						style={{
							transform: `translateX(${slidePerc * 100}%)`,
						}}
					/>
				</SliderBarWrap>
				<SliderSlide
					style={{
						width: `${slidePerc * 100}%`,
					}}>
					<SliderDot ref={handle}></SliderDot>
				</SliderSlide>
			</SliderMain>
		</SliderPadding>
	);
};

export default Slider;
