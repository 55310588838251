import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en"; // locale-data for en
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en"; // locale-data for en
// the above are polyfills for older versions of Safari
import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import color, { themeColors } from "../styles/colors";
import { fluidRestricted } from "../styles/mixins";
import { h3 } from "../styles/typography";
import { formatValue } from "react-currency-input-field";
import { clamp } from "../libs/math";
import { transparentize } from "polished";

const ValueMain = styled.div`
	color: ${(props) =>
		props.colorTheme === "lightGreen" || props.colorTheme === "darkGreen"
			? themeColors["lightGreen"].fg
			: props.colorTheme === "dark" || props.colorTheme === "light"
			? themeColors["light"].fg
			: undefined};
	text-align: center;
	position: relative;
	background: #ffffff;
	border: 1px solid
		${(props) => transparentize(0.5, themeColors[props.colorTheme].fg)};
	box-sizing: border-box;
	border-radius: 10px;
	${h3}
	font-weight:500;
	width: 100%;
	display: flex;
	${fluidRestricted("height", 40, 60)}
	justify-content:center;
	align-items: center;
`;

const Input = styled.input`
	opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
`;

const Value = styled.span`
	width: 100%;
	display: inline-block;
	margin-top: -0.1em;
`;

const TooltipTrack = styled.div`
	width: 100%;
	background: red;
	position: absolute;
	bottom: 0;
	left: 0;
	pointer-events: none;
`;

const TooltipWrap = styled.div`
	width: 30px;
	height: 14px;
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
	transform: translateX(50%);
`;

const Tooltip = styled.div`
	background-color: white;
	border: 1px solid
		${(props) => transparentize(0.5, themeColors[props.colorTheme].fg)};
	width: 30px;
	height: 30px;
	transform: translateY(-95%) rotate(45deg);
`;

const Carat = styled.span`
	@keyframes blink {
		0% {
			opacity: 1;
		}

		25% {
			opacity: 1;
		}

		50% {
			opacity: 0;
		}

		75% {
			opacity: 0;
		}
	}

	animation: blink 1s infinite;

	visibility: ${(props) => (props.show ? "visible" : "hidden")};
`;

function InputSliderValue({
	value,
	onUpdate,
	currency = true,
	range = { min: 0, max: 1 },
	defaultVal = 0,
	colorTheme = "dark",
	sliderPerc,
	suffix,
	id
}) {
	const inputRef = useRef();
	const [displayVal, setDisplayVal] = useState(`$${defaultVal}`);
	const [showCarat, setShowCarat] = useState(false);
	const [normalisedPerc, setNormalisedPerc] = useState(0);

	useEffect(() => {
		function focus() {
			setShowCarat(true);
		}

		function blur() {
			setShowCarat(false);
		}

		inputRef.current.addEventListener("focus", focus);
		inputRef.current.addEventListener("blur", blur);

		inputRef.current.value = defaultVal;
		setDisplayVal(defaultVal);

		return () => {
			inputRef.current.removeEventListener("focus", focus);
			inputRef.current.removeEventListener("blur", blur);
		};
	}, []);

	useEffect(() => {
		const num = Math.min(range.max, value);
		inputRef.current.value = num;

		const val = currency
			? formatValue({
					value: `${num}`,
					groupSeparator: ",",
					decimalSeparator: ".",
					prefix: "$",
					suffix,
			  })
			: formatValue({
					value: `${num}`,
			  });
		setDisplayVal(val);
	}, [value, suffix]);

	useEffect(() => {
		if (sliderPerc < 0.05) {
			setNormalisedPerc(0.05);
		} else if (sliderPerc > 0.95) {
			setNormalisedPerc(0.95);
		} else {
			setNormalisedPerc(sliderPerc);
		}
	}, [sliderPerc]);

	function changedVal() {
		let num = parseInt(inputRef.current.value) || 0;
		num = Math.min(range.max, num);
		inputRef.current.value = num;

		const val = formatValue({
			value: `${num}`,
			groupSeparator: ",",
			decimalSeparator: ".",
			prefix: "$",
			suffix,
		});

		onUpdate(clamp(num, range.min, range.max));
		setDisplayVal(val);
	}

	return (
		<ValueMain colorTheme={colorTheme}>
			<Input
				type="number"
				ref={inputRef}
				onChange={changedVal}
				min={range.min}
				max={range.max}
				id={id}
			/>
			<Value>
				{displayVal}
				<Carat show={showCarat}>|</Carat>
			</Value>
			{sliderPerc != undefined && (
				<TooltipTrack style={{ width: `${normalisedPerc * 100}%` }}>
					<TooltipWrap>
						<Tooltip colorTheme={colorTheme} />
					</TooltipWrap>
				</TooltipTrack>
			)}
		</ValueMain>
	);
}

export default InputSliderValue;
