import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import Slider from "./Slider";
import InputSliderValue from "./InputSliderValue";
import { h6, p } from "../styles/typography";
import { spacingRestricted } from "../styles/mixins";

const TopTextBox = styled.div`
	width: 100%;
	${spacingRestricted("margin-bottom", "xxs")}
`;

const Title = styled.h6`
	${h6};
	text-transform: uppercase;
`;

const SubTitle = styled(Title)`
	font-weight: 400;
	text-transform: initial;
`;

function InputSlider({
	range,
	onUpdate = () => {},
	currency = true,
	defaultVal,
	colorTheme = "dark",
	trackingName,
	title,
	subtitle,
	suffix,
	id
}) {
	const [inputVal, setInputVal] = useState(defaultVal);
	const [slideVal, setSlideVal] = useState(defaultVal);
	const [sliderPerc, setSliderPerc] = useState(0);

	useEffect(() => {
		onUpdate(inputVal);
	}, [inputVal]);

	useEffect(() => {
		onUpdate(slideVal);
	}, [slideVal]);
	return (
		<>
			{(title || subtitle) && (
				<TopTextBox>
					{title && <Title>{title}</Title>}
					{subtitle && <SubTitle>{subtitle}</SubTitle>}
				</TopTextBox>
			)}
			<InputSliderValue
				value={inputVal}
				onUpdate={setSlideVal}
				range={range}
				defaultVal={defaultVal}
				trackingName={trackingName}
				colorTheme={colorTheme}
				sliderPerc={sliderPerc}
				currency={currency}
				suffix={suffix}
				id={id}
			/>
			<Slider
				onUpdate={setInputVal}
				range={range}
				setSlide={slideVal}
				colorTheme={colorTheme}
				setSlidePercCallback={setSliderPerc}
			/>
		</>
	);
}

export default InputSlider;
