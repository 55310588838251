class Tracker {
	timeout = null;
	constructor(action, label, defaultVal) {
		this.action = action;
		this.label = label;
		this.val = defaultVal;
		this.dl = window ? window.dataLayer || [] : [];
		this.rand = Math.random();
		this.initd = true;
		setTimeout(() => {
			this.initd = false;
		}, 2000);
	}
	fire = (val) => {
		if (this.initd) return;
		if (val === this.val) return;

		clearTimeout(this.timeout);
		this.val = val;
		this.timeout = setTimeout(() => {
			this.dl.push({
				event: "change_form_field",
				event_info: {
					event_category: "Forms",
					event_action: this.action,
					event_label: this.label,
				},
			});
		}, 1000);
	};
}

export default Tracker;
