import React, { useState, useRef, useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { themeColors } from "../styles/colors";
import { spacingRestricted, screen } from "../styles/mixins";
import { metaSmall } from "../styles/typography";

const DisclaimerEl = styled.div`
	${spacingRestricted("margin-top", "s")};

	@media ${screen("lg")} {
		opacity: ${(props) => (props.colorTheme === "light" ? 0.5 : 1)};
	}
`;

const Content = styled.div`
	height: ${(props) => (props.isOpen ? props.height : 0)};
	overflow: hidden;
	transition: height 0.2s;
	text-align: ${(props) => (props.centered ? "center" : "left")};
`;

const ContentPadding = styled.div`
	${spacingRestricted("margin-top", "xs")};
`;

const HeadingButton = styled.button`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;

	&:focus {
		outline: none;
	}

	@media ${screen("lg")} {
		justify-content: flex-start;
		justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
	}
`;

const Heading = styled.p`
	${metaSmall};
	font-weight: 500;
	color: ${(props) => themeColors[props.colorTheme].accent1};

	@media ${screen("lg")} {
		color: ${(props) =>
			props.colorTheme === "light" || props.colorTheme === "greenLight"
				? themeColors[props.colorTheme].fg
				: themeColors[props.colorTheme].accent1};
	}
`;

const Icon = styled.div`
	width: 0.4em;
	height: 0.4em;
	position: relative;
	display: flex;
	align-items: center;
	margin-left: 0.8em;

	div {
		width: 100%;
		height: 1px;
		background-color: ${(props) => themeColors[props.colorTheme].accent1};
		position: absolute;

		&:last-of-type {
			transform: ${(props) => (props.isOpen ? "none" : "rotate(-90deg)")};
			transition: transform 0.2s;
		}
	}

	@media ${screen("lg")} {
		div {
			background-color: ${(props) =>
				props.colorTheme === "light" || props.colorTheme === "greenLight"
					? themeColors[props.colorTheme].fg
					: themeColors[props.colorTheme].accent1};
		}
	}
`;

const Disclaimer = ({
	heading,
	children,
	className,
	colorTheme = "light",
	centered,
}) => {
	const [open, setOpen] = useState(false);
	const [contentHeight, setContentHeight] = useState(0);

	const contentContainer = useRef();

	const updateScrollHeight = useCallback(() => {
		const contentScrollHeight = contentContainer.current.scrollHeight;
		setContentHeight(contentScrollHeight);
	}, []);

	useEffect(() => {
		updateScrollHeight();
		window.addEventListener("resize", updateScrollHeight);

		return () => {
			window.removeEventListener("resize", updateScrollHeight);
		};
	}, [updateScrollHeight]);

	const handleClick = (e) => {
		e.preventDefault();
		updateScrollHeight();
		setOpen(!open);

		window.dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: "expand_content",
			event_info: {
				event_category: "Content expansions",
				event_action: "Calculator disclaimer",
				event_label: heading,
			},
		});
	};

	return (
		<DisclaimerEl className={className}>
			<HeadingButton onClick={handleClick} centered={centered}>
				<Heading as="h3" colorTheme={colorTheme}>
					{heading}
				</Heading>
				<Icon colorTheme={colorTheme} isOpen={open}>
					<div></div>
					<div></div>
				</Icon>
			</HeadingButton>
			<Content
				ref={contentContainer}
				isOpen={open}
				height={`${contentHeight}px`}
				centered={centered}>
				<ContentPadding>{children}</ContentPadding>
			</Content>
		</DisclaimerEl>
	);
};

Disclaimer.propTypes = {
	colorTheme: PropTypes.string,
	heading: PropTypes.string,
};

Disclaimer.defaultProps = {
	colorTheme: "light",
};

export default Disclaimer;
