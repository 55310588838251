import React, { useState, useEffect } from 'react';

export default function useHeroImagePosition(targetRef) {

	const handleScroll = () => {
    const positionTop = window.pageYOffset;
    const windowWidth = window.innerWidth;
    const targetParent = targetRef.current.parentElement;
    const targetParentBcr = targetParent.getBoundingClientRect();

    if (windowWidth < 768) {
      if (positionTop + targetParentBcr.top < targetParentBcr.top + targetParentBcr.height ) {
        targetRef.current.style.transform = `translateY(${positionTop}px)`
      }
    } else {
      targetRef.current.style.transform = "";
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return null;
}